/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from "prop-types"
import React from "react"
import Snowfall from "react-snowfall"

import "./layout.css"

let snowflake = undefined

if (typeof window !== "undefined") {
  snowflake = document.createElement("img")
  snowflake.width = 50
  snowflake.height = 50
  snowflake.src = "/images/snowflake.png"
}

export default class Layout extends React.Component {
  render() {
    return (
      <>
        {this.props.children}
        <Snowfall
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
          }}
          speed={[0.5, 1.5]}
          images={[snowflake]}
          radius={[5, 18]}
          snowflakeCount={100}
        />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
